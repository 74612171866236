<template>
  <div>
  <b-card>

    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>

      </b-form-group>

    </div>

      <vue-good-table
        :columns="columns"
        :rows="reservations"
        :totalRows="totalRecords"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          searchFn: searchReservations,
        }"
        :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        @on-search="onSearch">
        >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
        <span
          v-if="props.column.field === 'reservation_number'"
          class="text-nowrap"
        >
            <router-link
              :to="{ path: `/groups/${props.row.group_id}/reservation/${props.row.id}` }"
              class="text-nowrap"
              style="cursor:pointer"
              tag="span"
            >
              {{ props.row.reservation_number }}
            </router-link>

        </span>

        <!-- Column: status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(getStatus(props.row))">
            {{ statusLabel(props.row.status) }}
          </b-badge>
        </span>

        <span v-if="props.column.field === 'group_name'" class="text-nowrap">
            <b-avatar :src="props.row.group_image" class="mx-1" :variant="avatarColors(flattenUUIDtoBase(props.row.group_id))"/>
            <router-link
              :to="{ name: 'organisation-group', params: { id: props.row.group_id }}"
              class="text-nowrap"
              style="cursor:pointer"
              tag="span"
            >
              {{ props.row.group_name }}
            </router-link>
          </span>

        <span v-else-if="props.column.field === 'primary_contact'">
            <span class="align-text-top text-capitalize">{{ getPrimaryContact(props.row) }}</span>
        </span>

          <!-- Column: type -->
          <span v-else-if="props.column.field === 'departure_date'">
            <span class="align-text-top text-capitalize">{{ props.row.departure_date }}</span>
        </span>
          <!-- Column: user_id -->
          <span v-else-if="props.column.field === 'user_id'">
           <b-badge variant='light-secondary' pill>
              <b-avatar
                :src="props.row.avatar"
                :variant="avatarColors(flattenUUIDtoBase(props.row.user_id))"
                size="sm"
                class='mr-1'
              />
              <span class="align-text-top text-capitalize">{{ getUser(props.row.user_id) }}</span>
            </b-badge>
        </span>

        <span v-else-if="props.column.field === 'booking_number'"
          class="align-text-top small"
          v-html="getBookingNumbers(props.row)">
        </span>
                          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <span>

            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
                <!--
                <b-dropdown-item @click="changeStatus('SUBMITTED',props.row)"
                                 v-if="props.row.status=='DRAFT'">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Marquer Complétée</span>
              </b-dropdown-item>
                <b-dropdown-item @click="changeStatus('DRAFT',props.row.id)"
                                 v-if="props.row.status=='SUBMITTED'">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Marquer Brouillon</span>

              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'group-edit-reservation', params: { reservation_id: props.row.id, id: props.row.group_id }}">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Modifier</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="deleteReservation(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Annuler</span>
              </b-dropdown-item>
              -->

              <b-dropdown-item @click="showRequestModal(props.row)">
                <feather-icon
                  icon="HelpCircleIcon"
                  class="mr-50"
                />
                <span>Demande modification/annulation</span>
              </b-dropdown-item>
              <ReservationRequestModal
                :ref="`reservation-request-modal-ref-${props.row.id}`"
                :reservation="reservationRequest"
              />
            </b-dropdown>
          </span>
        </span>

        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Indiquer 1 à
            </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

  </b-card>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ref, onMounted, nextTick } from '@vue/composition-api'
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ReservationRequestModal from "@/modules/requests/components/reservationRequestModal";
import {groupReservationService} from "@/views/groups/group/GroupReservations/GroupReservations";
import {GroupReservationService} from "@/views/groups/services/GroupReservationService";

export default {
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    VueGoodTable,
    ReservationRequestModal
  },
  directives: {
    Ripple,
  },
  setup () {
    const {getAllAvailableGroups, getOrgReservations} = useGroupApi();
    const {displayErrorMessage} = useToastNotification();
    const {logger, httpClient, route, router} = useApplicationContext();
    const {refOf} = useApplicationContext();
    const totalRecords = ref(0);
    const serverParams = {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        searchTerm: '',
        page: 1,
        perPage: 10
      }

    const reservations = ref([])
    const groups = ref([]);
    const currentAuthUser = ref({});
    const currentAgencyId = ref(null)
    const reservationRequest = ref({})
    const users = ref([])
    const getUsers = async () => {
      let response = await groupReservationService.getAllMembersOfOrg();
      users.value = response.data
    }
    const fetchReservations = async () => {
      try {
        groups.value = await getAllAvailableGroups();
        const organizationReservationsResponse = await getOrgReservations(serverParams);
        reservations.value = organizationReservationsResponse.data;
        totalRecords.value = organizationReservationsResponse.meta.total;
      } catch(e) {
        console.error(e)
        displayErrorMessage('Une erreur est survenue en essayant de chercher les groupes disponibles');
      }
    }

    const showRequestModal = (reservation) => {
      reservationRequest.value = reservation;
      nextTick(() => {
        refOf(`reservation-request-modal-ref-${reservation.id}`).$children[0].show();
      })
    };


    onMounted(async () => {

      currentAuthUser.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
      currentAgencyId.value = currentAuthUser.value.agency_id;

      fetchReservations()
    });

    const refetchData = () => {
      fetchReservations()
    }
    getUsers()
    return {
      reservations,
      refetchData,
      users,
      getUsers,
      showRequestModal,
      reservationRequest,
      serverParams,
      totalRecords,
    }

  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Numéro de réservation',
          field: 'reservation_number',
        },
        {
          label: 'Statut',
          field: 'status',
        },
        {
          label: 'Nom du groupe',
          field: 'group_name',
        },
        {
          label: 'Contact principal',
          field: 'primary_contact',
        },
        {
          label: "Date de départ",
          field: 'departure_date',
        },
        {
          label: 'Réservations Tour-opérateur',
          field: 'booking_number',
        },
        {
          label: 'Conseiller',
          field: 'user_id',
        },
        {
          label: 'Action',
          field: 'action',
        },

      ],
      searchTerm: '',
      onSearch: _.debounce(this.applySearch, 300)
    }
  },
  computed: {
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return character => Math.round(parseInt(character.slice(-1),UUID_BASE)/BASE)
    }
    ,
    avatarColors() {

      const avatarColors = {
        "1":"primary",
        "2":"dark",
        "3":"info",
        "4":"success",
        "5":"danger",
        "6":"warning",
        "7":"info",
        "8":"secondary",
      }
      return character => avatarColors[character]
    },
    statusVariant() {
      const statusColor = {
        DRAFT: "light-warning",
        CANCELLED: "light-danger",
        SUBMITTED: "light-success",
      };
      return (status) => statusColor[status];
    },
    statusLabel() {
      const statusLabel = {
        DRAFT: "Brouillon",
        CANCELLED: "Annulée",
        SUBMITTED: "Complétée",
      };
      return (status) => statusLabel[status];
    },
  },
  methods: {
    stringToHslColor(str, s, l) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      var h = hash % 360;
      return 'hsl('+h+', '+s+'%, '+l+'%)';
    },
    searchReservations(row, col, cellValue, searchTerm) {
      if (!searchTerm) {
        return true;
      }

      const term = this.normalizeString(searchTerm);
      const status = this.normalizeString(this.statusLabel(row.status));
      const packageName = this.normalizeString(this.getPackageName(row));
      const primaryContact = this.normalizeString(this.getPrimaryContact(row));
      const user = this.normalizeString(this.getUser(row.user_id));
      const group_name = this.normalizeString(row.group_name);

      return row.name.includes(term)
        || status.includes(term)
        || packageName.includes(term)
        || primaryContact.includes(term)
        || user.includes(term)
        || group_name.includes(term)
        || row.reservation_number.includes(term);
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.refetchData()
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.refetchData()
    },
    onSortChange(params) {
      this.serverParams.sort.field = params[0].field
      this.serverParams.sort.type = params[0].type
      this.refetchData()
    },
    applySearch(params) {
      this.serverParams.searchTerm = params.searchTerm || ''
      if (params.searchTerm != this.$route.query.search) {
        this.$router.push({ query: { search: params.searchTerm }});
      }
      this.refetchData();
    },
    getStatus(reservation) {
      if (reservation.status) {
        return reservation.status
      } else {
        return "in_progress"
      }
    },
    normalizeString(value) {
      return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    },
    getPrimaryContact(reservation) {
      let reservationOwner = false
      for (let i in reservation.package_reservations) {
        const element = reservation.package_reservations[i]
        reservationOwner = element.travelers.find(traveler => {
          return traveler.reservation_owner === true;
        })

        if (reservationOwner) {
          break
        }
      }

      if (reservationOwner) {
        return `${reservationOwner.first_name} ${reservationOwner.last_name}`;
      }
      return "N/A"
    },
    getPackageName(reservation) {
      try {
        let package_name = reservation.package_reservations[0].group_package.name
        return package_name;
      } catch (e) {
        return "Not selected"
      }
    },
     getUser(user_id) {
      let currentUser = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]

      try{

        if (currentUser.id === user_id) {
          return currentUser.fullName
        }
        let user = this.users.find(element=>{
          return element.id===user_id;
        })


        return user.full_name;
      } catch {
        return "N/A"
      }
    },
    getBookingNumbers(reservation) {
      try{
        let bookingNumberString = '';
        if (reservation.booking_numbers.length > 0) {
          reservation.booking_numbers.forEach(bookingNumber => {
            bookingNumberString += bookingNumber.operator + ': ' + bookingNumber.value + '<br>';
          });
        } else {
          bookingNumberString = "À venir"
        }
        return bookingNumberString;
      } catch {
        return ""
      }
    },

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
